import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import bg_pic from '../Media/Images/AIV03234.jpg';
import kansas from '../Media/Images/McDonald.png';
import AutoplayCarousel from '../components/AutoplayCarousel/AutoplayCarousel';
import PriceCard from '../components/PriceCard';


const HomePage = () => {
    const navigate = useNavigate();
    const listItem = "pl-2 ml-4 mb-4 text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] lg:text-[2rem]"

    const handlePackageClick = (pkg) => {
        navigate("/book");
    }

    return (
        <Layout>
            <div
                className="py-6 h-fit bg-no-repeat bg-cover text-PHEASANT_RED font-serif px-3"
                style={{ backgroundImage: `url(${bg_pic})` }}
            >
                {/* Content here if needed */}

                <div className="drop-shadow-[0_10px_10px_rgba(0,0,0,0.8)] font-bold font-['Times'] text-center pb-10">
                    <p className="text-orange-900 text-[2.5rem] sm:text-[3.25rem] md:text-[4rem] italic">Welcome to</p>
                    <p className="text-[PHEASANT_RED] text-[4rem] sm:text-[5rem] md:text-[6rem] leading-[3.5rem] sm:leading-[4.5rem] mb-5">
                        Beaver Creek Upland Hunts
                    </p>
                    <p className="text-orange-900 px-4
                                  text-[2rem] sm:text-[2.75rem] md:text-[3.5rem] leading-[2.25rem] sm:leading-[3.5rem] mb-[50px] italic">
                        Your home for a great Pheasant and Dove Hunting Experience
                    </p>
                </div>

                <div className="flex justify-center w-full">
                    <div className="mb-[75px] w-[85%] max-w-[1000px]">
                        <ul className="list-disc w-full text-[PHEASANT_RED] font-bold font-['Times'] italic leading-tight
                                       mb-[30px] bg-gray-200/50 rounded-[25px] p-8">
                            <li className={listItem}>Over 1000 acres of privately owned, managed hunting habitat</li>
                            <li className={listItem}>Fully guided hunts with experienced, well-trained hunting dogs</li>
                            <li className={listItem}>Local lodging available</li>
                            <li className={listItem}>Local Restaurants:  BBQ, Mexican, & the nationally recognized &nbsp;
                                <a href="https://www.travelawaits.com/2887619/best-steakhouses-in-the-us/" 
                                target="_blank" 
                                rel="noopener noreferrer">
                                    <span className="text-blue-900 underline visited:text-purple-600">Big Ed's Steakhouse</span></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="flex justify-center w-full">
                    <div className="w-[90%] max-w-[1000px] border-4 border-PHEASANT_RED rounded-[24px] bg-orange-200 mx-[20px] mb-5 font-bold text-center">
                        <p className="font-['Times'] text-black text-[2.5rem] md:text-[3.5rem] leading-[3.5rem] mb-5 mt-2">Book Now!<br></br>2025 Season is under way</p>
                        <p className="font-['Times'] text-black text-[1.75rem] md:text-[2.5rem] leading-[2.5rem] mb-5 mt-2">Now accepting bookings through Mar 31, 2025</p>
                        <p className="font-sans text-black text-[1.25rem] md:text-[1.75rem] leading-tight inline">Contact &nbsp;</p>
                        <p className="font-sans text-[PHEASANT_RED] text-[1.5rem] md:text-[2rem] leading-tight inline">Caleb Fisher</p>
                        <p className="font-sans text-black text-[1.25rem] md:text-[1.75rem] leading-tight">calebfisher2518@gmail.com</p>
                        <p className="font-sans text-black text-[1.5rem] md:text-[2rem] leading-tight mb-2">262-957-0454</p>
                    </div>
                </div>
                
            </div> 

        </Layout>
    );
};

export default HomePage



                {/* Location details
                <div className="mx-auto mt-12 h-[550px] w-[1000px] font-sans bg-contain bg-no-repeat"
                    style={{ backgroundImage: `url(${kansas})` }}
                >
                    <div className="ml-36 pt-8 text-[2rem] font-bold text-left">
                        <p>How to find us:</p>
                    </div>
                    <div className="ml-36 w-3/4 leading-none text-xl font-bold text-left">
                        <p className="leading-none mb-6 text-gray-900 font-bold">We are located in the Northwest corner of Kansas, in Rawlins county. McDonald is 38 miles from the Colorado border, just off U.S. Highway 36</p>
                        <p>From the North:</p>
                        <p className="ml-8 w-3/4 text-md text-gray-900">Highway 161 to Bird City then East 9 miles on US 36, or highway 25 to Atwood then West 18 miles on US 36. </p>
                        <p className="mt-4">From the South:</p>
                        <p className="ml-8 w-3/4 text-md text-gray-900">Highway 27 to Wheeler then East 18 miles on US 36, or highway 25 to Atwood then West 18 miles on US 36. </p>
                        <p className="mt-4">From the East:</p>
                        <p className="ml-8 w-3/4 text-md text-gray-900">I-70 to Exit 53 (Colby) then North on hwy 25 39 miles to Atwood and West on US 36 18 miles to McDonald.</p>
                        <p className="mt-4">From the West:</p>
                        <p className="ml-8 pb-8 w-3/4 text-md text-gray-900">I-70 to Exit 17 (Goodland) then North on hwy 27 30 miles to Wheeler and East on US 36 18 miles to McDonald.</p>
                    </div>
                </div> */}


