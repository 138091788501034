import React from 'react'
import { useNavigate } from 'react-router-dom';

const PriceCard = ({ pkgName, subTitle, pkgPrice, pkgElements }) => {
    const navigate = useNavigate();

    const listItems = pkgElements.map((item) =>
        <li className='mb-4' key={item.index}>{item}</li>
    )

    return (

        <div className="flex flex-col gap-4 p-3 border-4 border-PHEASANT_RED rounded-[24px] bg-orange-100/70">
            <div className="flex-1">
                <div className="py-3 text-center text-2xl bg-PHEASANT_RED rounded-[12px] text-gray-200">
                    <p>{pkgName}</p>
                    <p className="text-xl mt-2 text-orange-100">{subTitle}</p>
                </div>
                <div className="mt-8 h-[22rem] text-gray-900">
                    <ul className="text-left ml-10 list-disc text-lg font-semibold leading-none pr-4">
                        {pkgElements.map((item) =>
                            <li className="mb-4" key={item.index}>{item}</li>
                        )}
                    </ul>
                </div>
            </div>
            <div>
                <p className="mt-8 text-[2.5rem] text-center font-semibold">{`$${pkgPrice}`} <span className="text-3xl">/ person</span></p>
                {/* <button className="mt-1 mx-auto py-3 w-full bg-orange-900 text-white text-xl rounded-[12px] italic"
                        onClick ={ () => navigate('/book') }>Book Now!</button> */}
            </div>
        </div>

    )
}

export default PriceCard
