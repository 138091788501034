import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import bg_pic from '../Media/Images/AIV03234.jpg';
import PriceCard from '../components/PriceCard';

const PackagesPage = () => {
    const navigate = useNavigate();

    return (
        <Layout>
            <div
                className="py-6 h-fit bg-no-repeat bg-cover text-PHEASANT_RED font-serif px-3"
                style={{ backgroundImage: `url(${bg_pic})` }}
            >
                {/* Content here if needed */}

                {/* Options and Package prices */}
                <div className="flex justify-center text-center w-full font-['Times'] font-bold text-[4rem] italic mt-[4rem] mb-[6rem] leading-[3.25rem]">
                    Package Options
                </div>

                {/* <div className="flex flex-row justify-center text-[2rem] font-extrabold text-center space-x-12"> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-10">

                    <PriceCard 
                        pkgName='Half-Day Hunt'
                        subTitle='* * Popular Package * *'
                        pkgPrice={300} 
                        pkgElements={
                            [
                                'Includes one half-day (4hr) hunt',
                                'Fully guided hunt on private property',
                                '5 birds included',
                                'Extra birds can be purchased for $30 each',
                                'Dogs are provided',
                                'Includes cleaning of birds for transport',
                                'Lodging is available for an additional fee',
                            ]
                        }
                    />

                    <PriceCard 
                        pkgName='Full-Day Hunt'
                        subTitle='* * Popular Package * *'
                        pkgPrice={600} 
                        pkgElements={
                            [
                                'Includes two half-day (4hr) hunts',
                                'Fully guided hunts on private property',
                                '5 birds included per hunt',
                                'Extra birds can be purchased for $30 each',
                                'Dogs are provided',
                                'Includes cleaning of birds for transport',
                                'Lodging is available for an additional fee',
                            ]
                        }
                    />

                    <PriceCard 
                        pkgName='Deluxe Hunt'
                        subTitle=''
                        pkgPrice={1200} 
                        pkgElements={
                            [
                                'Includes three half-day (4hr) hunts',
                                'Fully guided hunts on private property',
                                '5 birds included per hunt',
                                'Extra birds can be purchased for $30 each',
                                'Includes 2 nights lodging and one dinner at nationally recognized steakhouse',
                                'Dogs are provided',
                                'Includes cleaning of birds for transport',
                            ]
                        }
                    />

                    <PriceCard 
                        pkgName='Self-Guided Hunt' 
                        subTitle=''
                        pkgPrice={250} 
                        pkgElements={
                            [
                                'One half-day (4hr) hunt',
                                'Self-Guided hunt on private property',
                                '5 birds included',
                                'Extra birds can be purchased for $30 each',
                                'Hunt over your own dogs if desired (kennels not provided)',
                                'Includes cleaning of birds for transport',
                                'Lodging is available for an additional fee',
                            ]
                        }
                    />

                    {/* Custom Package */}

                    <div className="flex flex-col gap-4 p-3 border-4 border-PHEASANT_RED rounded-[24px] bg-orange-100/70">
                        <div className="flex-1">
                            <div className="py-3 text-center text-2xl bg-PHEASANT_RED rounded-[12px] text-gray-200">
                                <p>Custom Package</p>
                            </div>
                            <div className="mt-8 h-[18rem] text-gray-900">
                                <p className="text-left ml-10 text-lg font-semibold leading-none pr-4">
                                    Don't see what you're looking for?  We're happy to customize a hunt to fit your specific needs.
                                </p>
                                <p className="text-center text-lg font-semibold leading-none mt-8 mb-4">Call for details and pricing:</p>
                                <p className="text-center text-[1.5rem] font-bold">Caleb Fisher<br></br>262-957-0454</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            
            </div>

        </Layout>
    );
};

export default PackagesPage